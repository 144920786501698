import React, { useState } from "react";
import Footer from "./Footer";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "./navbar";
import {
  searchFitnessCenter,
  searchByFitnessCenter,
} from "./../store/slices/fitnesscenter";
const Fitnesscenter = (props) => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [searchResults, setsearchResults] = useState([]);
  const location = useLocation();
  const [type, setType] = useState("");
  const [cities, setCities] = useState("");
  const [localities, setLocalities] = useState("");
  const values = location.state;
  const [datacount, setDataCount] = useState("");
  localStorage.setItem("service", values.fitnessServiceName);
  localStorage.setItem("city", values.cityName);
  localStorage.setItem("locality", values.locationName);

  React.useEffect(() => {
    dispatch(searchFitnessCenter())
      .unwrap()
      .then((data) => {
        setsearchResults(data.user.data);
        setDataCount(data.user.dataCount);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function searchCenter(e) {
    e.preventDefault();

    let item = { type, localities, cities };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(searchByFitnessCenter())
      .unwrap()
      .then((data) => {
        setsearchResults(data.user.data);
        setDataCount(data.user.dataCount);
        //setQuery("");
        // console.log(countVal);
      })
      .catch(({ message }) => {
        // //alert(message);
      });
  }

  return (
    <div id="__next">
      <Header />

      <section className="container pt-5 mt-5">
        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3 className="pt-3 pb-3">Fitness Center</h3>
        </div>
      </section>
      <section className="cover p-5">
        <h3 className="text-center col-white">Search Gym/Fitness Center</h3>
        <form onSubmit={searchCenter}>
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-12 pt-1 pb-1">
                <div className="col-12 mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                    value={cities}
                    onChange={(e) => setCities(e.target.value)}
                    style={{
                      lineHeight: "2",
                    }}
                  />
                </div>
              </div>

              <div className="col-md-3 col-sm-12 pt-1 pb-1">
                <div className="col-12 mt-1">
                  <input
                    type="text"
                    value={localities}
                    onChange={(e) => setLocalities(e.target.value)}
                    className="form-control"
                    placeholder="Location"
                    style={{
                      lineHeight: "2",
                    }}
                  />
                </div>
              </div>

              <div className="col-md-3 col-sm-12 pt-1 pb-1">
                <div className="col-12 mt-1">
                  <select
                    name="health"
                    id="fit"
                    className="select-opt"
                    style={{
                      lineHeight: "1.6",
                    }}
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="">Select Fitness Format</option>
                    <option>Gyms</option>
                    <option>Zumba</option>
                    <option>Cross Functional Training</option>
                    <option>Yoga</option>
                    <option>Fitness Studio</option>
                    <option>MMA</option>
                    <option>Kick Boxing</option>
                    <option>Dance</option>
                    <option>Pilates</option>
                  </select>
                </div>
              </div>

              <div className="col-md-3 col-sm-12 pt-1 pb-1">
                <div className="col-12 d-flex justify-content-center">
                  <button
                    className="btn btn-warning col-white inside-btn"
                    style={{ lineHeight: "2", border: "none" }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>

      {datacount === 0 && (
        <section className="pb-5">
          <div className="not-found-section" style={{ position: "relative" }}>
            <p className="wrapper-text">No fitness center found...!</p>
          </div>
        </section>
      )}
{datacount !== 0 && (
  <section className="img_section container pt-5 pb-5">
    <div className="row">
      {searchResults.map((centerVal, index) => (
        <div
          className="col-lg-6 col-md-4 col-sm-6 col-12 mb-4" // Responsive adjustments
          style={{ padding: "5px" }}
          key={index}
        >
          <div className="fitness-center-block">
            <div className="row">
              <div className="col-md-4 pad-right-0">
                <div className="fitness-center-image">
                  <Link to={`/FitnessCenterDetails/${centerVal._id || ""}`}>
                    <img
                      src={centerVal.logo} // Your image source
                      alt={centerVal.fcName}
                      className="trans_imgs img-fluid"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-md-6 pad-left-0">
                <div className="fitness-center-content">
                  <div className="center-name-loc">
                    <Link to={`/FitnessCenterDetails/${centerVal._id || ""}`} className="">
                      <h2 className="center-name mt-2 mb-2">{centerVal.fcName}</h2>
                    </Link>
                    <div className="center-location">
                      <i className="fa fa-map-marker" aria-hidden="true" style={{ color: "#f30" }}></i>{" "}
                      {`${centerVal.fcAddress.doorNoAndStreetName}, ${centerVal.fcAddress.city}, ${centerVal.fcAddress.state} - ${centerVal.fcAddress.pincode}`}
                    </div>
                  </div>
                  {/* <div className="center-review">
                    <span className="rating" style={{ background: "rgb(0,50,0)" }}>
                      {centerVal.rating || "N/A"}
                    </span>
                    <div className="review-count">{centerVal.reviews} Reviews</div>
                  </div> */}
                </div>
                <div className="col-12">
          <div className="d-flex flex-wrap">
            {/* Map through fitnessServices and render each item in a capsule box */}
            {centerVal?.fitnessServices.map((service) => (
              <div key={service.id} className="capsule-box">
                <h5>{service.name}</h5>
              </div>
            ))}
          </div>
        </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </section>
)}


      <Footer />
    </div>
  );
};

export default Fitnesscenter;
