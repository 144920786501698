import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import Footer from "./Footer";
import Header from "./navbar";

import Carousel from "react-bootstrap/Carousel";
import banner1 from "./../images/fitness/banner1.png";
import banner2 from "./../images/livewell/banner2.jpg";
import banner3 from "./../images/livewell/banner3.jpg";
import banner4 from "./../images/fitness/banner4.png";
import livewell from "./../images/livewell/livewell.jpg";
// slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { TravelEnquiry } from "../store/slices/enquiry";

const LiveWell = () => {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState("");
  const [alertname, setalertName] = useState("");
  const [alertemail, setalertEmail] = useState("");
  const [alertcontactNumber, setAlertcontactNumber] = useState("");
  const [alertmessage, setalertMessage] = useState("");
  const [alertcategory, setAlertcategory] = useState("");

  async function join(e) {
    e.preventDefault();
    let enquiryFor = "generalEnquiry";
    let category = "Live Well";
    let flow="healthCare"
    let item = {
      enquiryFor,
      name,
      email,
      contactNumber,
      message,
      category,
      flow
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        setName("");
        setEmail("");
        setContactNumber("");
        setMessage("");
        setCategory("");
        document.getElementById("join").style.display = "none";
      })

      .catch(({ emessage }) => {
        // alert("Error");
      });
  }

  const joinhealth = (e) => {
    const regname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regname.test(name)) {
      setalertName("");
    } else if (!regname.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("Please enter the valid name");
      e.preventDefault();
    }

    const regemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      setalertEmail("");
    } else if (!regemail.test(email) && email === "") {
      setalertEmail("Please enter the email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter the correct email address");
      e.preventDefault();
    }

    const regnumber = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regnumber.test(contactNumber)) {
      setAlertcontactNumber("");
    } else if (!regnumber.test(contactNumber) && contactNumber === "") {
      setAlertcontactNumber("Please enter the mobile number");
      e.preventDefault();
    } else {
      setAlertcontactNumber("Please enter the valid mobile number");
      e.preventDefault();
    }

    const regmessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regmessage.test(message)) {
      setalertMessage("");
    } else if (!regmessage.test(message) && message === "") {
      setalertMessage("Please enter the message");
      e.preventDefault();
    } else {
      setalertMessage("");
    }

    const regcat = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regcat.test(category)) {
      setAlertcategory("");
    } else if (!regcat.test(category) && category === "") {
      setAlertcategory("Please select");
    } else {
      setAlertcategory("");
    }
  };

  const reset = () => {
    setName("");
    setEmail("");
    setContactNumber("");
    setMessage("");
    setCategory("");

    setalertName("");
    setalertEmail("");
    setAlertcontactNumber("");
    setalertMessage("");
    setAlertcategory("");
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange !important",
          zIndex: "5",
          right: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange",
          zIndex: "5",
          left: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <SampleNextArrow style={{ color: "red", background: "black" }} />
    ),
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div>
      <Header />
      <section>
        <div className="carousel_cover position-relative">
          <Carousel className="">
            <Carousel.Item interval={2500}>
              <img className="d-block w-100 phy_img_carousel3" src={livewell} />
              <Carousel.Caption>
                <button
                  type="button"
                  className="btn btn-warning col-white"
                  style={{ fontSize: "20px" }}
                  data-bs-toggle="modal"
                  data-bs-target="#join"
                >
                  Book A Free Consultation
                </button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2500}>
              <img
                className="d-block w-100 phy_img_carousel3"
                src={banner2}
                alt=""
              />
              <Carousel.Caption>
                <button
                  type="button"
                  className="btn btn-warning col-white"
                  style={{ fontSize: "20px" }}
                  data-bs-toggle="modal"
                  data-bs-target="#join"
                >
                  Book A Free Consultation
                </button>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={2500}>
              <img className="d-block w-100 phy_img_carousel3" src={banner3} />
              <Carousel.Caption>
                <button
                  type="button"
                  className="btn btn-warning col-white"
                  style={{ fontSize: "20px" }}
                  data-bs-toggle="modal"
                  data-bs-target="#join"
                >
                  Book A Free Consultation
                </button>
              </Carousel.Caption>
            </Carousel.Item>
            {/* 
            <Carousel.Item interval={2500}>
              <img className="d-block w-100 phy_img_carousel" src={banner4} />
              <Carousel.Caption>
                <button
                  type="button"
                  className="btn btn-warning col-white"
                  style={{ fontSize: "20px" }}
                >
                  Book A Free Consultation
                </button>
              </Carousel.Caption>
            </Carousel.Item> */}
          </Carousel>
        </div>
      </section>

      <section className="section-card">
        <section className="container pt-5 pb-5">
          <div className="col-lg-12 d-flex justify-content-between">
            <p className="wel-text pb-2">MEDITATION</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              <div className="d-flex justify-content-center">
                <img src={banner1} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner2} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner3} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner4} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner1} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner2} alt="live-well" className="lie-img-slide" />
              </div>
            </Slider>
          </div>
        </section>
      </section>

      <section className="section-card">
        <section className="container pt-5 pb-5">
          <div className="col-lg-12 d-flex justify-content-between">
            <p className="wel-text pb-2">STRESS AND ANXIETY</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              <div className="d-flex justify-content-center">
                <img src={banner1} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner2} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner3} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner4} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner1} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner2} alt="live-well" className="lie-img-slide" />
              </div>
            </Slider>
          </div>
        </section>
      </section>

      <section className="section-card pt-2 pb-2">
        <section className="container pt-5 pb-5">
          <div className="col-lg-12 d-flex justify-content-between">
            <p className="wel-text pb-2">SLEEP</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              <div className="d-flex justify-content-center">
                <img src={banner1} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner2} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner3} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner4} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner1} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner2} alt="live-well" className="lie-img-slide" />
              </div>
            </Slider>
          </div>
        </section>
      </section>

      <section className="section-card pt-2 pb-2">
        <section className="container pt-5 pb-5">
          <div className="col-lg-12 d-flex justify-content-between">
            <p className="wel-text pb-2">SOUND THERAPY</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              <div className="d-flex justify-content-center">
                <img src={banner1} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner2} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner3} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner4} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner1} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner2} alt="live-well" className="lie-img-slide" />
              </div>
            </Slider>
          </div>
        </section>
      </section>

      <section className="section-card pt-2 pb-2">
        <section className="container pt-5 pb-5">
          <div className="col-lg-12 d-flex justify-content-between">
            <p className="wel-text pb-2">YOGA</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              <div className="d-flex justify-content-center">
                <img src={banner1} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner2} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner3} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner4} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner1} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner2} alt="live-well" className="lie-img-slide" />
              </div>
            </Slider>
          </div>
        </section>
      </section>

      <section className="section-card pt-2 pb-2">
        <section className="container pt-5 pb-5">
          <div className="col-lg-12 d-flex justify-content-between">
            <p className="wel-text pb-2">Looking for an expert</p>
          </div>
          <div className="container">
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-warning col-white"
                style={{ fontSize: "20px" }}
                data-bs-toggle="modal"
                data-bs-target="#join"
              >
                Request Now
              </button>
            </div>
          </div>
        </section>
      </section>

      <section className="section-card pt-2 pb-2">
        <section className="container pt-5 pb-5">
          <div className="col-lg-12 d-flex justify-content-between">
            <p className="wel-text pb-2">Blog</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              <div className="d-flex justify-content-center">
                <img src={banner1} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner2} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner3} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner4} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner1} alt="live-well" className="lie-img-slide" />
              </div>

              <div className="d-flex justify-content-center">
                <img src={banner2} alt="live-well" className="lie-img-slide" />
              </div>
            </Slider>
          </div>
        </section>
      </section>

      <Footer />

      <div className="modal fade" id="join" style={{ height: "100%" }}>
        <div
          className="modal-dialog modal-align-center"
          style={{ position: "relative", height: "100%", marginTop: "0%" }}
        >
          <div className="modal-contents">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 className="modal-title">Book Appointment</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={reset}
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={join}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                      />
                      <p className="alert-message">{alertname}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                      />
                      <p className="alert-message">{alertemail}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="number"
                        className="form-control"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                        placeholder="Mobile Number"
                      />
                      <p className="alert-message">{alertcontactNumber}</p>

                      <br />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <textarea
                        row="50"
                        col="50"
                        className="form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Describe in brief"
                      ></textarea>
                      <p className="alert-message">{alertmessage}</p>

                      <br />
                    </div>

                    {/* <div className="col-lg-12 col-md-12 col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      value={userId}
                      onChange={(e) => setUserId(e.target.value)}
                      placeholder="Enter your User id"
                    />
                    <p className="alert-message">{alertuserid}</p>

                    <br />
          </div>*/}
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-warning  col-white"
                    onClick={joinhealth}
                  >
                    Book Now
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white"
                    onClick={reset}
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="background" data-bs-dismiss="modal" />
      </div>
    </div>
  );
};

export default LiveWell;
