import axios from "axios";
import { API_BASE_URL, API_PATHS } from "../utils/constants/api.constants";

class BookingService {
   

    static Pricing(item) {
        return axios
            .post(API_PATHS.calculatePrice,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    static HotelPricing(item) {
        return axios
            .post(API_PATHS.calculatehotelPrice,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    static Bookings() {
        let api='';
        let user = JSON.parse(localStorage.getItem("user"));
        let id = user.data.id;
        api = API_PATHS.booking+ '?userId=' + id;
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static Booking(item) {
        return axios
            .post(API_PATHS.packagebooking,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

      static StorePayment(item) {
        return axios
            .post(API_PATHS.paymentStore,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static GetselectExpertise() {
        let api='';
           const data="getTopExpertise=1"
        api = API_PATHS.selectExpertise+ '?' + data;
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default BookingService;