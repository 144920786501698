import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "./navbar";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import { getFitnessDetails } from "./../store/slices/fitnesscenter";
import { useNavigate } from "react-router-dom";
import Travelfooter from "./Travelfooter";



function Traveldetails() {


  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const plans = [
    { id: '1Day', title: '1-Day', price: '₹150', description: 'Get access to the gym for a single day.' },
    { id: '1Week', title: '1-Week', price: '₹600', description: 'One week of unlimited access to all facilities.' },
    { id: '1Month', title: '1-Month', price: '₹1500', description: 'Full access for one month with additional benefits.' },
    { id: '3Months', title: '3-Months', price: '₹4000', description: 'Three months of access at a discounted rate.' },
    { id: '6Months', title: '6-Months', price: '₹7500', description: 'Six months of gym access with priority support.' },
    { id: '1Year', title: '1-Year', price: '₹14000', description: 'Full year access with personal training sessions included.' },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("id", id);
  const [fcName, setfcName] = useState();
  const [imageUrl, setimageUrl] = useState([]);
  const [logo, setLogo] = useState("");
  const [centeraddress, setcenterAddress] = useState("");
  const [about, setAbout] = useState("");
  React.useEffect(() => {
    dispatch(getFitnessDetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          setfcName(data.user.data[0].fcName);
          setimageUrl(data.user.data[0].mediaLink);
          setcenterAddress(data.user.data[0].fcAddress);
          setAbout(data.user.data[0].about);
          setLogo(data.user.data[0].logo);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div id="_next">
      <Header />

      <section>
        <div className="carousel_cover position-relative">
          <Carousel className="length">
            {imageUrl.map((image, index) => (
              <Carousel.Item interval={2500}>
                <img className="d-block w-100 phy_img_carousel2" src={image} alt="" />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </section>
      <section
        className="container"
        style={{
          border: "solid 1px silver",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <div className="d-flex justify-content-left">
          <div className="row pb-1">
            <div className="col-lg-3 col-md-3 col-sm-12 ">
              <img src={logo} alt="" />
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 ">
              <h3 className="text-left  pb-2" style={{ color: "black" }}>
                {fcName}
              </h3>
              <p style={{ whiteSpace: "pre-wrap" }}>
                <i className="fa fa-map-marker"></i>&nbsp;
                {centeraddress.doorNoAndStreetName}, {centeraddress.city},&nbsp;
                {centeraddress.state} {centeraddress.pincode}
              </p>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section
        className="container"
        style={{
          border: "solid 1px silver",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <div className="d-flex justify-content-left">
          <h3 className="text-center  pb-2" style={{ color: "black" }}>
            About {fcName}
          </h3>
        </div>

        <div className="row pb-1">
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <p style={{ whiteSpace: "pre-wrap" }}>{about}</p>
          </div>
        </div>
      </section>

      <section
        className="container mt-4"
        style={{
          border: "solid 1px silver",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <div className="d-flex justify-content-left ">
          <div className="row pb-1">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
              <h3 className="text-left  pb-2" style={{ color: "black" }}>
                Amenities
              </h3>
              <p style={{ whiteSpace: "pre-wrap" }}>
                <i className="fa fa-map-marker"></i>&nbsp;
                {centeraddress.doorNoAndStreetName}, {centeraddress.city},&nbsp;
                {centeraddress.state} {centeraddress.pincode}
              </p>
            </div>
          </div>
        </div>
      </section>


      <section
      className="container mt-4"
      style={{
        border: "solid 1px silver",
        borderRadius: "10px",
        padding: "20px",
      }}
    >
      <h3 className="mb-4">Gym Membership Plans</h3>
      <div className="accordion" id="membershipPlansAccordion" style={{ border: "none" }}>
        {plans.map((plan, index) => (
          <div className="card" key={plan.id} style={{ border: "none" }}>
            <div
              className="card-header w-100 d-flex justify-content-between align-items-center"
              id={`heading${index}`}
              style={{ background: "#f06900", cursor: "pointer" }}
              onClick={() => toggleAccordion(index)}
            >
              <h5 className="mb-0 d-flex justify-content-between align-items-center text-white w-100">
                <span>{plan.title}</span>
                <span className="d-flex align-items-center">
                  Starts from {plan.price}
                  {activeIndex === index ? (
                    <FaChevronUp className="ml-2" />
                  ) : (
                    <FaChevronDown className="ml-2" />
                  )}
                </span>
              </h5>
            </div>
            <div
              id={`collapse${index}`}
              className={`collapse ${activeIndex === index ? "show" : ""}`}
              aria-labelledby={`heading${index}`}
              data-parent="#membershipPlansAccordion"
            >
              <div className="card-body">
                <p>{plan.description}</p>
                <button className="btn btn-warning text-white">Buy {plan.title}</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
      <br />
      <br />
      <Travelfooter />
    </div>
  );
}

export default Traveldetails;
