import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Footer from "./Footer";
import Slider from "react-slick";
import expert from "./../images/blog/nutritionist.png";
import tool from "./../images/blog/tool.png";
import fitness from "./../images/blog/fit.jpg";
import meter from "./../images/blog/healthmeter.png";
import transform from "./../images/homepage/Transform With Healthonify App.png";
import shop from "./../images/blog/shop.jpg";
import shop1 from "./../images/shop/shop1.jpg";
import shop2 from "./../images/shop/shop2.webp";
import shop3 from "./../images/shop/shop3.jpg";
import shop4 from "./../images/shop/shop4.jpg";
import shop5 from "./../images/shop/shop5.jpg";
import isometric from "./../images/homepage/isometric.jpg";
import home1 from "./../images/homepage/banner1.jpg";
import home2 from "./../images/homepage/banner2.jpg";
import home3 from "./../images/homepage/banner3.jpg";
import home4 from "./../images/homepage/banner4.jpg";
import home5 from "./../images/homepage/banner5.jpg";
import home6 from "./../images/homepage/banner6.jpg";
import home7 from "./../images/Web-Banner.png";
import banner1 from "./../images/homepage/homepage1.png";
import banner2 from "./../images/homepage/homepage2.png";
import banner3 from "./../images/homepage/homepage3.png";
import banner4 from "./../images/homepage/homepage4.png";
import Gapp from "./../images/project/Gapp.png";
import Iapp from "./../images/project/apple.png";
import BannerLogo from "./../images/BannerLogo.jpg";
import { useNavigate, Link } from "react-router-dom";

import Carousel from "react-bootstrap/Carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./navbar";
import { GiSleepingBag } from "react-icons/gi";
import { FaRunning } from "react-icons/fa";
import { IoFastFoodSharp } from "react-icons/io5";
import { FaPersonBooth } from "react-icons/fa";
import { GiMeditation } from "react-icons/gi";
import { GiThreeFriends } from "react-icons/gi";
import { GiHealthPotion } from "react-icons/gi";
import { GiMountainClimbing } from "react-icons/gi";
import { SpecialPackage } from "../store/slices/package";
import { TravelEnquiry } from "../store/slices/enquiry";
import { WmEnquiry } from "../store/slices/enquiry";

import { getblog } from "../store/slices/blog";

import livewell1 from "./../images/homepage/Live-well/banner-01.png";
import livewell2 from "./../images/homepage/Live-well/banner-02.png";
import livewell3 from "./../images/homepage/Live-well/banner-03.png";
import livewell4 from "./../images/homepage/Live-well/banner-04.png";
import livewell5 from "./../images/homepage/Live-well/banner-05.png";
import livewell6 from "./../images/homepage/Live-well/banner-06.png";
import Moment from "moment";

//testimonial images
import Kshemendra from "./../images/testimonial/Kshemendra.png";
import Nidhi from "./../images/testimonial/nidhi.png";
import Jagdish from "./../images/testimonial/Jagdish.png";
import Gurjinder from "./../images/testimonial/Gurjinder Singh.png";
// home images

const Home = () => {
  const [special, setSpecial] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate("");
  // Form Validaiton
  const [city, setCity] = useState("");
  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState("");
  const [query, setQuery] = useState("");
  const [locality, setLocality] = useState("");

  const [destination, setDestination] = useState("");
  const [travelDate, settravelDate] = useState("");
  const [childCount, setchildCount] = useState("");
  const [adultCount, setadultCount] = useState("");
  const [goal, setGoal] = useState("");
  const [messages, setMessages] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [profession, setProfession] = useState("");
  const [healthConditions, setHealthConditions] = useState("");
  const [prefferedLanguage, setPrefferedLanguage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [alertcompanyName, setAlertCompanyName] = useState("");

  const [alertname, setalertName] = useState("");
  const [alertemail, setalertEmail] = useState("");
  const [alertcontactNumber, setAlertcontactNumber] = useState("");
  const [alertmessage, setalertMessage] = useState("");
  const [alertcategory, setAlertcategory] = useState("");

  const [alertmobile, setalertMobile] = useState("");
  const [alertpeople, setAlertpeople] = useState("");
  const [alertdestination, setAlertDestination] = useState("");

  const [alertname2, setAlertname2] = useState("");
  const [alertemail2, setAlertemail2] = useState("");
  const [alertprofession2, setAlertprofession2] = useState("");
  const [alerthealthcondition2, setAlerthealthcondition2] = useState("");
  const [alertprefferedlanguage2, setAlertprefferedlanguage2] = useState("");
  const [alertmessage2, setAlertmessage2] = useState("");
  const [alertcontactnumber2, setAlertcontactnumber2] = useState("");
  const [alertage2, setAlertage2] = useState("");
  const [alertgender2, setAlertgender2] = useState("");
  const [enquiryFor, setenquiryFor] = useState("");
  const [alertname10, setAlertname10] = useState("");
  const [alertemail10, setAlertemail10] = useState("");
  
  const [alertmessage10, setAlertmessage10] = useState("");
  const [alertcontactnumber10, setAlertcontactnumber10] = useState("");
  const [alertage10, setAlertage10] = useState("");
  const [alertgender10, setAlertgender10] = useState("");
  const [alertcity10, setalertCity10] = useState("");
  const [alertdate10, setalertDate10] = useState("");
  const [alertcategory10, setalertCategory10] = useState("");

  const [alertdate, setalertDate] = useState("");
  const [alertadult, setAlertadult] = useState("");
  const [alertchild, setAlertchild] = useState("");
  const [alertenquiryFor, setalertenquiryFor] = useState("");

  const [errorname, seterrorName] = useState("");
  const [errormessage, seterrorMessage] = useState("");
  const [erroremail, seterrorEmail] = useState("");
  const [errorcontactNumber, seterrorcontactNumber] = useState("");
  const [errorCategory, seterrorCategory] = useState("");

  async function freeconsult(e) {
    e.preventDefault();
    let enquiryFor = "generalEnquiry";
    let flow="healthCare"
    let item = {
      enquiryFor,
      name,
      email,
      contactNumber,
      message,
      category,
      city,
      date,
      flow
    };
    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        setName("");
        setEmail("");
        setContactNumber("");
        setMessage("");
        setCategory("");
        setGender("");
        setDate("");
        setAge("");
        setCity("");
      })

      .catch(({ emessage }) => {
        alert(emessage);
      });
  }

  const freeEnquiry = (e) => {
    const regxname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxname.test(name)) {
      setAlertname10("");
    } else if (!regxname.test(name) && name === "") {
      setAlertname10("Please enter your name");
      e.preventDefault();
    } else {
      setAlertname10("");
    }

    const regemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      setAlertemail10("");
    } else if (!regemail.test(email) && email === "") {
      setAlertemail10("Please enter your email");
      e.preventDefault();
    } else {
      setAlertemail10("Please enter a valid email address");
      e.preventDefault();
    }

    const regxcity = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxcity.test(city)) {
      setalertCity10("");
    } else if (!regxname.test(city) && city === "") {
      setalertCity10("Please enter your city name");
      e.preventDefault();
    } else {
      setalertCity10("");
    }

    const vDate2 =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (vDate2.test(date)) {
      setalertDate10("");
    } else if (!vDate2.test(date) && date === "") {
      setalertDate10("Please enter your date of consultation");
      e.preventDefault();
    } else {
      setalertDate10("");
    }

    if (gender === "") {
      setAlertgender10("Please select gender");
      e.preventDefault();
    } else {
      setAlertgender10("");
    }

    if (category === "") {
      setalertCategory10("Please select");
      e.preventDefault();
    } else {
      setalertCategory10("");
    }

    const regnumber1 = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regnumber1.test(contactNumber)) {
      setAlertcontactnumber10("");
    } else if (!regnumber1.test(contactNumber) && contactNumber === "") {
      setAlertcontactnumber10("Please enter your mobile number");
      e.preventDefault();
    } else {
      setAlertcontactnumber10("Please enter a valid mobile number");
      e.preventDefault();
    }

    const regxMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxMessage.test(message)) {
      setAlertmessage10("");
    } else if (!regxMessage.test(message) && message === "") {
      setAlertmessage10("Please enter message");
      e.preventDefault();
    } else {
      setAlertmessage10("");
    }

    const regxage = /^(1[89]|[2-9]\d)$/;
    if (regxage.test(age)) {
      setAlertage10("");
    } else if (!regxage.test(age) && age === "") {
      setAlertage10("Please enter  your age");
      e.preventDefault();
    } else {
      setAlertage10("");
    }
  };

  async function join(e) {
    e.preventDefault();
    let enquiryFor = "generalEnquiry";
    let item = {
      enquiryFor,
      name,
      email,
      contactNumber,
      message,
      category,
    };
    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        setName("");
        setEmail("");
        setContactNumber("");
        setMessage("");
        setCategory("");
        document.getElementById("join").style.display = "none";
      })

      .catch(({ emessage }) => {
        alert(emessage);
      });
  }
  const myDatas = {
    fitnessServiceName: query,
    cityName: city,
    locationName: locality,
  };

  async function joinFitness(e) {
    e.preventDefault();
    navigate("/Fitness-center/", { state: myDatas });
  }

  const joinhealth = (e) => {
    const regmessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regmessage.test(message)) {
      setalertMessage("");
    } else if (!regmessage.test(message) && message === "") {
      setalertMessage("Please enter your query");
      e.preventDefault();
    } else {
      setalertMessage("");
    }

    const regemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      setalertEmail("");
    } else if (!regemail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email address");
      e.preventDefault();
    }

    const regnumber = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regnumber.test(contactNumber)) {
      setAlertcontactNumber("");
    } else if (!regnumber.test(contactNumber) && contactNumber === "") {
      setAlertcontactNumber("Please enter your mobile number");
      e.preventDefault();
    } else {
      setAlertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const regcat = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regcat.test(category)) {
      setAlertcategory("");
    } else if (!regcat.test(category) && category === "") {
      setAlertcategory("Please select");
    } else {
      setAlertcategory("");
    }

    const regname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regname.test(name)) {
      setalertName("");
    } else if (!regname.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("Please enter a valid name");
      e.preventDefault();
    }
  };

  const joinfit = (e) => {
    const regmessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regmessage.test(message)) {
      seterrorMessage("");
    } else if (!regmessage.test(message) && message === "") {
      seterrorMessage("Please enter your query");
      e.preventDefault();
    } else {
      seterrorMessage("");
    }

    const regemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      seterrorEmail("");
    } else if (!regemail.test(email) && email === "") {
      seterrorEmail("Please enter your email");
      e.preventDefault();
    } else {
      seterrorEmail("Please enter a valid email address");
      e.preventDefault();
    }

    const regnumber = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regnumber.test(contactNumber)) {
      seterrorcontactNumber("");
    } else if (!regnumber.test(contactNumber) && contactNumber === "") {
      seterrorcontactNumber("Please enter your mobile number");
      e.preventDefault();
    } else {
      seterrorcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const regcat = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regcat.test(category)) {
      seterrorCategory("");
    } else if (!regcat.test(category) && category === "") {
      seterrorCategory("Please select");
    } else {
      seterrorCategory("");
    }

    const regname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regname.test(name)) {
      seterrorName("");
    } else if (!regname.test(name) && name === "") {
      seterrorName("Please enter your name");
      e.preventDefault();
    } else {
      seterrorName("Please enter a valid name");
      e.preventDefault();
    }
  };

  const joinfreehealth = (e) => {
    const vDate2 =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (vDate2.test(date)) {
      setalertDate("");
    } else if (!vDate2.test(date) && date === "") {
      setalertDate("Please enter your date of consultation");
      e.preventDefault();
    } else {
      setalertDate("");
    }

    const regmessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regmessage.test(message)) {
      setalertMessage("");
    } else if (!regmessage.test(message) && message === "") {
      setalertMessage("Please enter your query");
      e.preventDefault();
    } else {
      setalertMessage("");
    }

    const regemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      setalertEmail("");
    } else if (!regemail.test(email) && email === "") {
      setalertEmail("Please enter your  email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email address");
      e.preventDefault();
    }

    const regnumber = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regnumber.test(contactNumber)) {
      setAlertcontactNumber("");
    } else if (!regnumber.test(contactNumber) && contactNumber === "") {
      setAlertcontactNumber("Please enter mobile number");
      e.preventDefault();
    } else {
      setAlertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const regcat = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regcat.test(category)) {
      setAlertcategory("");
    } else if (!regcat.test(category) && category === "") {
      setAlertcategory("Please select");
    } else {
      setAlertcategory("");
    }

    const regname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regname.test(name)) {
      setalertName("");
    } else if (!regname.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("Please enter a valid name");
      e.preventDefault();
    }
  };
  const joinphy = (e) => {
    const regname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regname.test(name)) {
      setalertName("");
    } else if (!regname.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("Please enter a valid name");
      e.preventDefault();
    }

    const regemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      setalertEmail("");
    } else if (!regemail.test(email) && email === "") {
      setalertEmail("Please enter email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email address");
      e.preventDefault();
    }

    const regnumber = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regnumber.test(contactNumber)) {
      setAlertcontactNumber("");
    } else if (!regnumber.test(contactNumber) && contactNumber === "") {
      setAlertcontactNumber("Please enter mobile number");
      e.preventDefault();
    } else {
      setAlertcontactNumber("Please enter a valid mobile number");
      e.preventDefault();
    }

    const regmessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regmessage.test(message)) {
      setalertMessage("");
    } else if (!regmessage.test(message) && message === "") {
      setalertMessage("Please enter message");
      e.preventDefault();
    } else {
      setalertMessage("");
    }
  };

  const reset = () => {
    setName("");
    setEmail("");
    setContactNumber("");
    setMessage("");
    setCategory("");

    setalertName("");
    setalertEmail("");
    setAlertcontactNumber("");
    setalertMessage("");
    setAlertcategory("");
  };

  useEffect(() => {
    document.title = "Healthonify";
  }, []);

  // ----**----

  //get travelonify special package

  React.useEffect(() => {
    dispatch(SpecialPackage())
      .unwrap()
      .then((data) => {
        setSpecial(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  //slick

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange !important",
          zIndex: "5",
          right: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          //background: "orange",
          color: "orange",
          zIndex: "5",
          left: "-2%",
          borderRadius: "5px",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  React.useEffect(() => {
    dispatch(getblog())
      .unwrap()
      .then((data) => {
        setBlogs(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <SampleNextArrow style={{ color: "red", background: "black" }} />
    ),
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  async function next(e) {
    let category = "Physiotherapy";
    let enquiryFor = "generalEnquiry";
    e.preventDefault();
    let item = {
      name,
      contactNumber,
      message,
      email,
      enquiryFor,
      category,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        setMessage("");
        setName("");
        setEmail("");
        setContactNumber("");
        document.getElementById("myenquirytherapy").style.display = "none";
      })
      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }

  const peopleCount = adultCount + " Adult, " + childCount + " Children ";

  async function travelpackageEnquiry(e) {
    e.preventDefault();
    let enquiryFor = "travelPackage";
    let item = {
      name,
      email,
      contactNumber,
      message,
      enquiryFor,
      travelDate,
      destination,
      peopleCount,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        setName("");
        setEmail("");
        setContactNumber("");
        setMessage("");
        setDestination("");
        settravelDate("");
        setchildCount("");
        setadultCount("");
        document.getElementById("travelenquiry").style.display = "none";
      })

      .catch(({ emessage }) => {
        // alert(emessage);
      });
  }

  const travelsave = (e) => {
    const vName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vName.test(name)) {
      setalertName("");
    } else if (!vName.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("Please enter a valid name");
      e.preventDefault();
    }

    const vMessage = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (vMessage.test(message)) {
      setalertMessage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMessage("Please enter your message");
      e.preventDefault();
    } else {
      setalertMessage("");
    }

    const vDestination = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (vDestination.test(destination)) {
      setAlertDestination("");
    } else if (!vDestination.test(destination) && destination === "") {
      setAlertDestination("Please enter destination");
      e.preventDefault();
    } else {
      setAlertDestination("");
    }

    const regNum = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regNum.test(contactNumber)) {
      setalertMobile("");
    } else if (!regNum.test(contactNumber) && contactNumber === "") {
      setalertMobile("Please enter your mobile number");
      e.preventDefault();
    } else {
      setalertMobile("Valid mobile numbers only");
      e.preventDefault();
    }

    const regcount = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regcount.test(peopleCount)) {
      setAlertpeople("");
    } else if (!regcount.test(peopleCount) && peopleCount === "") {
      setAlertpeople("Please enter no. people");
      e.preventDefault();
    } else {
      setAlertpeople("");
    }

    const vAdult = /^[1-9][0-9]?$|^100$/;
    if (vAdult.test(adultCount)) {
      setAlertadult("");
    } else if (!vAdult.test(adultCount) && adultCount === "") {
      setAlertadult("Please enter  no.of adults");
      e.preventDefault();
    } else {
      setAlertadult("Min value should be 1");
      e.preventDefault();
    }

    const vDate =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (vDate.test(travelDate)) {
      setalertDate("");
    } else if (!vDate.test(travelDate) && travelDate === "") {
      setalertDate("Please enter your date of travel");
      e.preventDefault();
    } else {
      setalertDate("");
    }

    const child = /^[0-9][0-9]?$|^100$/;
    if (child.test(childCount)) {
      setAlertchild("");
    } else if (!child.test(childCount) && childCount === "") {
      setAlertchild("Please enter  no.of child");
      e.preventDefault();
    } else {
      setAlertchild("");
    }

    const regemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      setalertEmail("");
    } else if (!regemail.test(email) && email === "") {
      setalertEmail("Please enter your email");
      e.preventDefault();
    } else {
      setalertEmail("Please enter a valid email");
      e.preventDefault();
    }
  };

  const clearform = () => {
    setName("");
    setEmail("");
    setContactNumber("");
    setMessage("");
    setDestination("");
    settravelDate("");
    setchildCount("");
    setadultCount("");

    // alert message clear
    setalertName("");
    setalertEmail("");
    setalertMobile("");
    setalertMessage("");
    setAlertpeople("");
    setAlertDestination("");
    setalertDate("");
    setAlertcontactNumber("");
    setAlertadult("");
    setAlertchild("");
  };

  const formclear = () => {
    setName("");
    setEmail("");
    setAge("");
    setProfession("");
    setHealthConditions("");
    setPrefferedLanguage("");
    setMessage("");
    setContactNumber("");

    setAlertname2("");
    setAlertemail2("");
    setAlertprofession2("");
    setAlerthealthcondition2("");
    setAlertprefferedlanguage2("");
    setAlertmessage2("");
    setAlertcontactnumber2("");
    setAlertage2("");
    setAlertgender2("");
  };

  const handleChange = (e) => {
    const target = e.target;
    if (target.checked) {
      setGender(target.value);
    }
  };

  const weightform = (e) => {
    e.preventDefault();
    let enquiryFor = "WeightLoss";
    let role = "client";
    const message = messages + " Goal is " + goal;
    let item = {
      name,
      email,
      profession,
      healthConditions,
      prefferedLanguage,
      message,
      contactNumber,
      enquiryFor,
      gender,
      age,
      role,
    }; 
    localStorage.setItem("item", JSON.stringify(item));
    
    dispatch(WmEnquiry(item))
      .unwrap()
      .then((data) => {
        // setResult(data.user.data);
        setName("");
        setEmail("");
        setProfession("");
        setHealthConditions("");
        setPrefferedLanguage("");
        setMessage("");
        setContactNumber("");
        setenquiryFor("");
        setGender("");
        setAge("");

        document.getElementById("freeconsultation").style.display = "none";
        alert("Enquiry submitted succesfully! We will get back to you.");
      })

      .catch(({ message }) => {
        // //alert(message);
      });
  };

  const freeconsultation = (e) => {
    const regxname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxname.test(name)) {
      setAlertname2("");
    } else if (!regxname.test(name) && name === "") {
      setAlertname2("Please enter your name");
      e.preventDefault();
    } else {
      setAlertname2("");
    }

    const regxCondition = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxCondition.test(healthConditions)) {
      setAlerthealthcondition2("");
    } else if (
      !regxCondition.test(healthConditions) &&
      healthConditions === ""
    ) {
      setAlerthealthcondition2("Please select ");
      e.preventDefault();
    } else {
      setAlerthealthcondition2("");
    }

    if (gender === "") {
      setAlertgender2("Please select gender");
      e.preventDefault();
    } else {
      setAlertgender2("");
    }

    const regxProfession = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxProfession.test(profession)) {
      setAlertprofession2("");
    } else if (!regxProfession.test(profession) && profession === "") {
      setAlertprofession2("Please select ");
      e.preventDefault();
    } else {
      setAlertprofession2("");
    }

    const regxLanguage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxLanguage.test(prefferedLanguage)) {
      setAlertprefferedlanguage2("");
    } else if (
      !regxLanguage.test(prefferedLanguage) &&
      prefferedLanguage === ""
    ) {
      setAlertprefferedlanguage2("Please select");
      e.preventDefault();
    } else {
      setAlertprefferedlanguage2("");
    }

    const regxemail =
      /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regxemail.test(email)) {
      setAlertemail2("");
    } else if (!regxemail.test(email) && email === "") {
      setAlertemail2("Please enter email");
      e.preventDefault();
    } else {
      setAlertemail2("Please enter the correct email address");
      e.preventDefault();
    }

    const regxmobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regxmobile.test(contactNumber)) {
      setAlertcontactnumber2("");
    } else if (!regxmobile.test(contactNumber) && contactNumber === "") {
      setAlertcontactnumber2("Please enter contact number");
      e.preventDefault();
    } else {
      setAlertcontactnumber2("Please enter a valid contact number");
      e.preventDefault();
    }

    const regxMessage = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regxMessage.test(messages)) {
      setAlertmessage2("");
    } else if (!regxMessage.test(messages) && messages === "") {
      setAlertmessage2("Please enter message");
      e.preventDefault();
    } else {
      setAlertmessage2("");
    }

    const regxage = /^(1[89]|[2-9]\d)$/;
    if (regxage.test(age)) {
      setAlertage2("");
    } else if (!regxage.test(age) && age === "") {
      setAlertage2("Please enter  your age");
      e.preventDefault();
    } else {
      setAlertage2("");
    }
  };

  async function uploadcorporate(e) {
    e.preventDefault();
    let enquiryFor = "companyOnboarding";
    let item = {
      name,
      email,
      companyName,
      message,
      contactNumber,
      enquiryFor,
    };

    dispatch(TravelEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry submitted succesfully! We will get back to you.");
        setName("");
        setEmail("");
        setCompanyName("");
        setMessage("");
        setContactNumber("");

        document.getElementById("enquire").style.display = "none";
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }

  const joinen = (e) => {
    const regemail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      setalertEmail("");
    } else if (!regemail.test(email) && email === "") {
      setalertEmail("Please enter  email address");
    } else {
      setalertEmail("Please enter a valid email address");
    }

    const textname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (textname.test(name)) {
      setalertName("");
    } else if (!textname.test(name) && name === "") {
      setalertName("Please enter your name");
      e.preventDefault();
    } else {
      setalertName("");
      e.preventDefault();
    }

    const testname2 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (testname2.test(companyName)) {
      setAlertCompanyName("");
    } else if (!testname2.test(companyName) && companyName === "") {
      setAlertCompanyName("Please enter company name");
      e.preventDefault();
    } else {
      setAlertCompanyName("");
      e.preventDefault();
    }

    const vMessage = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (vMessage.test(message)) {
      setalertMessage("");
    } else if (!vMessage.test(message) && message === "") {
      setalertMessage("Please enter message");
      e.preventDefault();
    } else {
      setalertMessage("");
      e.preventDefault();
    }

    const regNum = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regNum.test(contactNumber)) {
      setAlertcontactNumber("");
    } else if (!regNum.test(contactNumber) && contactNumber === "") {
      setAlertcontactNumber("Please enter  mobile number");
    } else {
      setAlertcontactNumber("Please enter a valid mobile number");
    }
  };

  const clear = () => {
    setName("");
    setEmail("");
    setCompanyName("");
    setMessage("");
    setContactNumber("");

    setalertName("");
    setAlertCompanyName("");
    setalertMessage("");
    setAlertcontactNumber("");
    setalertEmail("");
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-warning verticalButton buttonLink"
        data-bs-toggle="modal"
        data-bs-target="#consult"
      >
        FREE CONSULTATION
      </button>

      <div id="__next">
        <Header />

        <section>
          <div className="carousel_cover">
            <Carousel className="">
              <Carousel.Item
                interval={3000}
                style={{ position: "relative" }}
              >
                <img
                  className="d-block w-100 phy_img_carousel3"
                  src={home7}
                  alt=""
                />



                <Carousel.Caption>
                  <div className="logo-hide">
                    <div className="app-logo d-flex align-items-center justify-content-center gap-3">
                      <a href="https://play.google.com/store/apps/details?id=com.octal.healthonify&pli=1">
                        <img
                          src={Gapp}
                          alt="Download on Google Play"
                          style={{ height: "50px" }}
                        />
                      </a>
                      <a href="https://apps.apple.com/app/healthonify-app/id6450786642">
                        <img
                          src={Iapp}
                          alt="Download on the App Store"
                          style={{ height: "50px" }}
                        />
                      </a>
                    </div>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img
                  className="d-block w-100 phy_img_carousel3"
                  src={home1}
                  alt=""
                />
                <Carousel.Caption>
                  <Link to="/HRA">
                    <button
                      type="button"
                      className="btn btn-healthonify  col-white"
                      style={{
                        color: "box",
                        lineHeight: "2 !important",
                        // width: "200px",
                        height: "50px",
                      }}
                    >
                      TAKE HEALTH RISK ASSESSMENT
                    </button>
                  </Link>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img
                  className="d-block w-100 phy_img_carousel3"
                  src={home2}
                  alt=""
                />
                <Carousel.Caption>
                  <button
                    type="button"
                    className="btn btn-healthonify  col-white"
                    data-bs-toggle="modal"
                    data-bs-target="#travelenquiry"
                    style={{
                      color: "box",
                      lineHeight: "2 !important",
                      // width: "200px",
                      height: "50px",
                    }}
                  >
                    ENQUIRE NOW
                  </button>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item interval={3000}>
                <img
                  className="d-block w-100 phy_img_carousel3"
                  src={home3}
                  alt="fintness center near me"
                />
                <Carousel.Caption>
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#fitnessjoin"
                    className="btn btn-healthonify  col-white"
                    style={{
                      color: "box",
                      lineHeight: "2 !important",
                      // width: "200px",
                      height: "50px",
                    }}
                  >
                    FIND A FITNESS CENTER NEAR YOU
                  </button>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item interval={3000}>
                <img className="d-block w-100 phy_img_carousel3" src={home4} />
                <Carousel.Caption>
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#freeconsultation"
                    className="btn btn-healthonify col-white"
                    style={{
                      color: "box",
                      lineHeight: "2 !important",
                      // width: "200px",
                      height: "50px",
                    }}
                  >
                    ENQUIRE NOW
                  </button>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item interval={3000}>
                <img className="d-block w-100 phy_img_carousel3" src={home5} />
                <Carousel.Caption>
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#myenquirytherapy"
                    className="btn btn-healthonify col-white"
                    style={{
                      color: "box",
                      lineHeight: "2 !important",
                      // width: "200px",
                      height: "50px",
                    }}
                  >
                    ENQUIRE NOW
                  </button>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item interval={3000}>
                <img className="d-block w-100 phy_img_carousel3" src={home6} />
                <Carousel.Caption>
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#enquire"
                    className="btn btn-healthonify col-white"
                    style={{
                      color: "box",
                      lineHeight: "2 !important",
                      // width: "200px",
                      height: "50px",
                    }}
                  >
                    LEARN MORE
                  </button>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
        </section>

        <section className="container ">
          <div className="col-lg-12 d-flex justify-content-center section-title">
            <h3 className="pt-5 ">Welcome To Healthonify</h3>
          </div>
          <h3 className="text-center pb-2">
            The One Stop Holistic Digital Healthcare Platform
          </h3>

          <h5 style={{ fontWeight: "650" }} className="text-center">
            TEAM HEALTHONIFY
          </h5>
          <p className="text-paragraph">
            Team Healthonify has decades of collective experience in the field
            of Health and Fitness. We understand Health and Wellness to the
            core. Having seen the Health & Fitness Industry in over a decade,
            interreacting with thousands of Health and Fitness members spread
            across the country, understanding their problems, be it their
            Chronic Ailments (NCD’s), Fitness, Weight Management, Physiotherapy
            and other health issues and guiding them to change their lifestyle,
            we felt the need of One stop Shop platform integrating Health and
            Wellness. – A Tech platform to serve the entire population and help
            everyone lead a healthy lifestyle. We have created many success
            stories and case studies in the field of Health and Wellness, based
            on which we have devised the Healthonify Ecosystem.
          </p>
        </section>

        <section className="d-flex justify-content-center pt-4 pb-2">
          <Link to="/HRA">
            <button
              type="button"
              className="btn btn-warning col-white btn-single"
              data-bs-toggle="modal"
            >
              TAKE A HEALTH RISK ASSESSMENT NOW
            </button>
          </Link>
        </section>

        <div className="container">
          <p className="text-paragraph">
            <b>Do you know</b> , that most of the medical conditions can be
            reversed with proper medical advice and life style changes. Get a
            Healthonify Health Risk Assessment done based on which we can do the
            risk prediction and recommend you the required care plan with our
            panel of experts. Based on the result of your Health Risk
            Assessment, our team will guide you to the required Health experts –
            Doctor, Physiotherapists, Dieticians, Yoga Expert, Fitness trainers,
            Meditation experts, Sound therapists, Sleep experts, Healers to name
            a few. who will handhold you throughout your journey with
            Healthonify.
          </p>
        </div>

        <section className="features-sections pt-5 pb-5">
          <div className="container">
            <div className="col-lg-12 d-flex justify-content-center section-title">
              <h3 className="pt-5 text-center">
                All-In-One Healthonify Ecosystem
              </h3>
            </div>

            <div className="row ">
              <div className="col-lg-12 d-flex justify-content-center">
                <img src={isometric} alt="" className="tilt-image" />
              </div>
            </div>

            <p>
              Healthonify ecosystem comprehensively focuses on the six
              dimensions of well-being: physical, social, emotional, financial,
              occupational, and purpose.
            </p>

            <p>
              Healthonify is a complete Tech based Health and Wellness platform
              which connects the user and services throughout the day and night
              - 24 x 7. Healthonify App has all the required features which
              helps the user to lead a healthy lifestyle, be it your Expert
              consultations, step count, water intake, diet tracker or your
              fitness activities, weight management, physiotherapy, Ayurveda,
              relaxation, your health vitals, your health records, just to name
              a few. Healthonify App is very user friendly and it motivates the
              user to explore more and more about the app. We call it, Your
              health is in your pocket. Healthonify App is integrated with
              leading wellness technologies & services that you know and love.
              Features are clean, easy-to-navigate user friendly, and is full of
              multi-media and holistic wellness content.
              <span style={{ fontWeight: "500", color: "black" }}>
                Come – Experience Healthonify
              </span>
            </p>
          </div>
        </section>

        <div className="col-lg-12 d-flex justify-content-center section-title">
          <h3 className="text-center">Transform With Healthonify App</h3>
        </div>
        <div className="row">
          <div className="col-md-9">
            <img src={transform} alt="" />
          </div>
          <div className="col-md-3 text-center mt-5">
            <img
              src={Gapp}
              alt=""
              style={{ height: "80px" }}
              className="mt-10"
            />{" "}
            <br />
            <img src={Iapp} alt="" style={{ height: "80px" }} />
          </div>
        </div>
        <section className="head">
          <ul className="container li-text-point pt-3">
            <li>
              <span>Do you Know</span> What is your present health condition?
              What is your current health score.
            </li>
            <br />
            <li>
              <span>Do you know</span> What health risks you may face in the
              coming months / years.
            </li>
            <br />
            <li>
              <span>Do you know,</span> India has an estimated 77 million people
              (1 in 11 Indians) formally diagnosed with diabetes, which makes it
              the second most affected in the world, after China.
            </li>
            <br />
            <li>
              <span>
                Do you know- 56 million Indians suffer from depression and
                another 38 million Indians suffer from anxiety disorders.
              </span>
            </li>
            <br />
            <li>
              <span>
                Do you know, the long-term complications of COVID-19 that may
                arise could be cardiovascular, neurological and psychological,
                haematological, pulmonary and many other disorders.
              </span>
            </li>
            <br />
          </ul>
        </section>

        <section className="container pt-5 pb-1">
          <div className="col-lg-12 d-flex justify-content-center section-title">
            <h3 className="text-center">Monitor Your Health</h3>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
              <Link to="/Health-meter">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img src={meter} alt="round" className="round_img" />
                </div>
                <p className="round-text ">Health Meter</p>
              </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
              <Link to="/Tools">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img src={tool} alt="round" className="round_img" />
                </div>
                <p className="round-text ">Tools</p>
              </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
              <Link to="/Expert">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img src={expert} alt="round" className="round_img" />
                </div>
                <p className="round-text ">Experts</p>
              </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
              <Link to="/Fitness">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center">
                  <img src={fitness} alt="round" className="round_img" />
                </div>
                <p className="round-text ">Fitness Center</p>
              </Link>
            </div>
          </div>
        </section>

        <section>
          <div className="carousel_cover mb-5 pb-3">
            <Carousel>
              <Carousel.Item interval={3000}>
                <img
                  className="d-block w-100 phy_img_carousel2"
                  src={banner1}
                  alt="offer"
                />
                <Carousel.Caption>
                  <button
                    type="button"
                    className="btn btn-warning col-white"
                    data-bs-toggle="modal"
                    data-bs-target="#join"
                    style={{
                      color: "box",
                      lineHeight: "2 !important",
                      width: "200px",
                      height: "50px",
                    }}
                  >
                    Join Now
                  </button>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={2500}>
                <img
                  className="d-block w-100 phy_img_carousel2"
                  src={banner2}
                  alt="offer"
                />
                <Carousel.Caption>
                  <button
                    type="button"
                    className="btn btn-warning col-white"
                    data-bs-toggle="modal"
                    data-bs-target="#join"
                    style={{
                      color: "box",
                      lineHeight: "2 !important",
                      width: "200px",
                      height: "50px",
                    }}
                  >
                    Join Now
                  </button>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item interval={2500}>
                <img
                  className="d-block w-100 phy_img_carousel2 "
                  src={banner3}
                  alt="offer"
                />
                <Carousel.Caption>
                  <button
                    type="button"
                    className="btn btn-warning col-white"
                    data-bs-toggle="modal"
                    data-bs-target="#join"
                    style={{
                      color: "box",
                      lineHeight: "2 !important",
                      width: "200px",
                      height: "50px",
                    }}
                  >
                    Join Now
                  </button>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item interval={2500}>
                <img
                  className="d-block w-100 phy_img_carousel2 "
                  src={banner4}
                  alt="offer"
                />
                <Carousel.Caption>
                  <button
                    type="button"
                    className="btn btn-warning col-white"
                    data-bs-toggle="modal"
                    data-bs-target="#join"
                    style={{
                      color: "box",
                      lineHeight: "2 !important",
                      width: "200px",
                      height: "50px",
                    }}
                  >
                    Join Now
                  </button>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
        </section>

        <section className="container pb-5">
          <div className="col-lg-12 d-flex justify-content-center section-title">
            <h3 className="top-texts wel-text text-center pt-5 pb-3">
              We Cater to All the Needs of An Individual's Lifestyle through Our
              Experts Who Will Guide You to Lead a Healthy and Balanced Life.
            </h3>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 pt-2 pb-2">
              <Link to="/LiveWell">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 bg-shaw">
                  <div className="col-lg-12 d-flex justify-content-center">
                    <GiSleepingBag className="r-icons" />
                  </div>
                  <p className="text-style col-bold-white">Sleep Management</p>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 pt-2 pb-2">
              <Link to="/Fitness">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 bg-shaw">
                  <div className="col-lg-12 d-flex justify-content-center">
                    <FaRunning className="r-icons" />
                  </div>
                  <p className=" text-style col-bold-white"> Fitness</p>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 pt-2 pb-2">
              <Link to="/Weight-loss">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 bg-shaw">
                  <div className="col-lg-12 d-flex justify-content-center">
                    <IoFastFoodSharp className="r-icons" />
                  </div>
                  <p className=" text-style col-bold-white"> Right Diet</p>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 pt-2 pb-2">
              <Link to="/Livewell">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 bg-shaw">
                  <div className="col-lg-12 d-flex justify-content-center">
                    <FaPersonBooth className="r-icons" />
                  </div>
                  <p className=" text-style col-bold-white">
                    Stress Management
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 pt-2 pb-2">
              <Link to="/Livewell">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 bg-shaw">
                  <div className="col-lg-12 d-flex justify-content-center">
                    <GiMeditation className="r-icons" />
                  </div>
                  <p className=" text-style col-bold-white"> Meditation</p>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 pt-2 pb-2">
              <Link to="/Expert">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 bg-shaw">
                  <div className="col-lg-12 d-flex justify-content-center">
                    <GiThreeFriends className="r-icons" />
                  </div>
                  <p className=" text-style col-bold-white">
                    Expert Consultation
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 pt-2 pb-2">
              <Link to="/HealthCare">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 bg-shaw">
                  <div className="col-lg-12 d-flex justify-content-center">
                    <GiHealthPotion className="r-icons" />
                  </div>
                  <p className=" text-style col-bold-white"> Health Care</p>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 pt-2 pb-2">
              <Link to="/travel">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 bg-shaw">
                  <div className="col-lg-12 d-flex justify-content-center">
                    <GiMountainClimbing className="r-icons" />
                  </div>
                  <p className=" text-style col-bold-white"> Adventure</p>
                </div>
              </Link>
            </div>
          </div>
        </section>
        <div className="cover">
          <section className="container booking pt-3 pb-5 ">
            <form onSubmit={freeconsult}>
              <div className="col-lg-12 pt-1 pb-3 d-flex justify-content-center">
                <p className="head-text2 text-center">
                  Book A Free Consultation With Our Expert
                </p>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      style={{ lineHeight: "2 " }}
                    />{" "}
                    <p className="alert-message"> {alertname10}</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      placeholder="Enter your email"
                      style={{ lineHeight: "2 " }}
                    />

                    <p className="alert-message"> {alertemail10}</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <input
                      type="number"
                      value={contactNumber}
                      onChange={(e) => setContactNumber(e.target.value)}
                      className="form-control"
                      placeholder="Enter your mobile number "
                      style={{ lineHeight: "2 " }}
                    />

                    <p className="alert-message"> {alertcontactnumber10}</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <input
                      type="text"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      className="form-control"
                      placeholder="Enter your city "
                      style={{ lineHeight: "2 " }}
                    />

                    <p className="alert-message"> {alertcity10}</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <input
                      type="number"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      className="form-control"
                      placeholder="Enter your age "
                      style={{ lineHeight: "2 " }}
                    />

                    <p className="alert-message"> {alertage10}</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <select
                      name="item"
                      id="sub-item"
                      className="select-opt"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="">Enquiry For</option>
                      <option value="Fitness Expert">Fitness Expert</option>
                      <option value="Health Expert">Health Expert</option>
                      <option value="Weight Expert">Weight Expert</option>
                      <option value="Physiotherapy">Physiotherapy</option>
                      <option value="Travel">Travel</option>
                      <option value="Ayurveda">Ayurveda</option>
                      <option value="Mindfulness">Mindfulness</option>
                      <option value="Others">Others</option>
                    </select>

                    <p className="alert-message"> {alertcategory10}</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <select
                      name="item"
                      id="sub-item"
                      className="select-opt"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="">Gender</option>
                      <option value="trainer">Male</option>
                      <option value="trainer">Female</option>
                      <option value="Other">Other</option>
                    </select>

                    <p className="alert-message"> {alertgender10}</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <input
                      type="date"
                      value={date}
                      min={Moment().add(0, "d").format("YYYY-MM-DD")}
                      onChange={(e) => setDate(e.target.value)}
                      className="form-control"
                      placeholder="Enter Your Number "
                      style={{ lineHeight: "2 " }}
                    />

                    <p className="alert-message"> {alertdate10}</p>
                  </div>
                </div>
                

                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-1 pb-4">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <textarea
                      row="10"
                      col="10"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="form-control"
                      placeholder="Describe your issue "
                    />

                    <p className="alert-message"> {alertmessage10}</p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <p className="col-white">
                  By clicking Request Now, you agree to our&nbsp;
                  <a
                    href="https://healthonify.com/Terms-condition"
                    target="_blank"
                    style={{ color: "#ffc107" }}
                  >
                    Terms and Conditions
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    href="https://healthonify.com/Privacy-policy"
                    target="_blank"
                    style={{ color: "#ffc107" }}
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
              <div className="col-lg-12 d-flex justify-content-center mt-3">
                <button
                  type="submit"
                  onClick={freeEnquiry}
                  className="btn btn-warning col-white submit-btn"
                >
                  Request Now
                </button>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <button
                  type="reset"
                  onClick={reset}
                  className="btn btn-blue col-white submit-btn"
                >
                  Reset
                </button>
              </div>
            </form>
          </section>
        </div>

        <section className="livewell container pt-5 pb-5">
          <div className="col-lg-12">
            <p className="wel-text text-center pb-2">Live Well</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              <div className="d-flex justify-content-center">
                <Link to="/Livewell">
                  <img
                    src={livewell1}
                    alt="live-well"
                    className="lie-img-slide-2"
                  />
                </Link>
              </div>

              <div className="d-flex justify-content-center">
                <Link to="/Livewell">
                  <img
                    src={livewell2}
                    alt="live-well"
                    className="lie-img-slide-2"
                  />
                </Link>
              </div>

              <div className="d-flex justify-content-center">
                <Link to="/Livewell">
                  <img
                    src={livewell3}
                    alt="live-well"
                    className="lie-img-slide-2"
                  />
                </Link>
              </div>

              <div className="d-flex justify-content-center">
                <Link to="/Livewell">
                  <img
                    src={livewell4}
                    alt="live-well"
                    className="lie-img-slide-2"
                  />
                </Link>
              </div>

              <div className="d-flex justify-content-center">
                <Link to="/Livewell">
                  <img
                    src={livewell5}
                    alt="live-well"
                    className="lie-img-slide-2"
                  />
                </Link>
              </div>

              <div className="d-flex justify-content-center">
                <Link to="/Livewell">
                  <img
                    src={livewell6}
                    alt="live-well"
                    className="lie-img-slide-2"
                  />
                </Link>
              </div>
            </Slider>
          </div>
        </section>

        <section className="livewell container pt-5 pb-5">
          <div className="col-lg-12">
            <p className="wel-text text-center pb-2">Travel</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              {special.map((PackageVal, index) => (
                <Link
                  to={`/travelonify/Traveldetails/${
                    PackageVal._id ? PackageVal._id : null
                  }`}
                >
                  <div className="slide slick-space">
                    <div className="card_slicks">
                      <img
                        src={PackageVal.imageUrl[0].mediaLink}
                        className="slick-img"
                        alt=""
                      />

                      <h4
                        className="card-title slick_text"
                        style={{
                          fontSize: "15px",
                          padding: "5px",
                          textAlign: "center",
                        }}
                      >
                        {PackageVal.packageName.substring(0, 30)}
                      </h4>
                    </div>
                  </div>
                </Link>
              ))}
            </Slider>
          </div>
        </section>

        <section className="livewell container pt-5 pb-5">
          <div className="col-lg-12">
            <p className="wel-text text-center pb-2">Shop</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              <Link to="/Shop">
                <div className="d-flex justify-content-center">
                  <img src={shop1} alt="" className="lie-img-slide" />
                </div>
              </Link>
              <Link to="/Shop">
                <div className="d-flex justify-content-center">
                  <img src={shop2} alt="" className="lie-img-slide" />
                </div>
              </Link>

              <Link to="/Shop">
                <div className="d-flex justify-content-center">
                  <img src={shop3} alt="" className="lie-img-slide" />
                </div>
              </Link>

              <Link to="/Shop">
                <div className="d-flex justify-content-center">
                  <img src={shop4} alt="" className="lie-img-slide" />
                </div>
              </Link>

              <Link to="/Shop">
                <div className="d-flex justify-content-center">
                  <img src={shop5} alt="" className="lie-img-slide" />
                </div>
              </Link>
            </Slider>
          </div>
        </section>

        <section className="livewell container pt-5 pb-5">
          <div className="col-lg-12">
            <p className="wel-text text-center pb-2">Blog</p>
          </div>
          <div className="container">
            <Slider {...settings}>
              {blogs.map((blogsVal, index) => (
                <div className="d-flex justify-content-center">
                  <Link to={`/blog/${blogsVal._id ? blogsVal._id : null}`}>
                    <img
                      src={blogsVal.mediaLink}
                      alt=""
                      className="lie-img-slide"
                    />
                    <p>{blogsVal.blogTitle}</p>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </section>

        <section className="testimonial">
          <div className="container">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p className="wel-text text-center pb-2">Testimonial</p>
            </div>

            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="single-features-item bg-orange-card fix_space">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={Kshemendra} alt="" />
                  </div>
                  <div className="circle-profile d-flex justify-content-center">
                    <b className="col-white  justify-content-center">
                      Kshemendra
                    </b>
                  </div>
                  <p className="col-white">
                    I was not aware of my health condition. Healthonify App
                    especially the Health Risk Assessment has enlighted me and I
                    have changed my lifestyle to face the future. The Tools and
                    trackers educate you on your progress. Its motivating.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="single-features-item bg-orange-card fix_space">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={Nidhi} alt="" />
                  </div>
                  <div className="circle-profile d-flex justify-content-center">
                    <b className="col-white  justify-content-center">Nidhi</b>
                  </div>
                  <p className="col-white">
                    We are back from our wonderful trip to Muscat and all thanks
                    to Travelonify. Planning and bookings were well done as
                    expected. Your set itinerary made the best use of our time
                    and got the best price advantage.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="single-features-item bg-orange-card fix_space">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={Jagdish} alt="" />
                  </div>
                  <div className="circle-profile d-flex justify-content-center">
                    <b className="col-white  justify-content-center">Jagdish</b>
                  </div>
                  <p className="col-white">
                    I am a marathon runner. I started using the Healthonify App
                    which really changed my routine. I get all required thinks
                    on the app like trackers, vitals, my fitness plans, diet
                    plan, consultation with experts particularly the chat with
                    Physiotherapists. The more you explore, the more you get on
                    the App.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="single-features-item bg-orange-card fix_space">
                  <div className="circle-profile d-flex justify-content-center">
                    <img src={Gurjinder} alt="" />
                  </div>
                  <div className="circle-profile d-flex justify-content-center">
                    <b className="col-white  justify-content-center">
                      Gurjinder Singh
                    </b>
                  </div>
                  <p className="col-white">
                    I was fed up and confused after trying so many Gyms, Apps
                    etc.I could not understand my efforts going in vain. Dr
                    Priyanka helped me understand my thyroid function. I
                    appreciate her patience with me and after regular monitoring
                    from Sanjana, for the first time iam feeling lighter. This
                    has changed my life and using the App keeps me in constant
                    touch with my health coach.
                  </p>
                </div>
              </div>
            </div>

            <br />
            <br />
          </div>
        </section>

        <Footer />
        {/*Join Now Form*/}
        <div className="modal fade" id="join" style={{ height: "100%" }}>
          <div
            className="modal-dialog modal-align-center"
            style={{ position: "relative", height: "100%", marginTop: "0%" }}
          >
            <div className="modal-contents">
              <div
                className="modal-header"
                style={{ justifyContent: "center" }}
              >
                <h4 className="modal-title">Get in touch with Us</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={reset}
                ></button>
              </div>

              <div className="modal-body">
                <form onSubmit={join}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                        />
                        <p className="alert-message">{alertname}</p>

                        <br />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                        />
                        <p className="alert-message">{alertemail}</p>

                        <br />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <input
                          type="number"
                          className="form-control"
                          value={contactNumber}
                          onChange={(e) => setContactNumber(e.target.value)}
                          placeholder="Mobile Number"
                        />
                        <p className="alert-message">{alertcontactNumber}</p>

                        <br />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <select
                          name="health"
                          id="healthy"
                          className="select-opt"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option value="">Select Category</option>
                          <option value="Weight Loss">Weight Loss</option>
                          <option value="Physiotherapy">Physiotherapy</option>
                          <option value="Travel">Travel</option>
                          <option value="Health-Care">Health Care</option>
                          <option value="Mindfulness">Mindfulness</option>
                          <option value="Stress Management">
                            Stress Management
                          </option>
                          <option value="Fitness">Fitness</option>
                        </select>
                        <p className="alert-message">{alertcategory}</p>
                        <br />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <textarea
                          row="50"
                          col="50"
                          className="form-control"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Describe about query in brief"
                        ></textarea>
                        <p className="alert-message">{alertmessage}</p>

                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-warning  col-white"
                      onClick={joinhealth}
                    >
                      Request a Call Back
                    </button>
                    &nbsp; &nbsp;
                    <button
                      type="reset"
                      className="btn btn-blue col-white"
                      onClick={reset}
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="background" data-bs-dismiss="modal" />
        </div>

        <div className="modal fade" id="fitnessjoin" style={{ height: "100%" }}>
          <div
            className="modal-dialog modal-align-center"
            style={{ position: "relative", height: "100%", marginTop: "0%" }}
          >
            <div className="modal-contents">
              <div
                className="modal-header"
                style={{ justifyContent: "center" }}
              >
                <h4 className="modal-title">Search Fitness Center</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={reset}
                ></button>
              </div>

              <div className="modal-body">
                <form onSubmit={joinFitness}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <input
                          type="text"
                          className="form-control"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          placeholder="City"
                          required
                        />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          value={locality}
                          onChange={(e) => setLocality(e.target.value)}
                          placeholder="Location"
                        />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                        <select
                          name="health"
                          id="healthy"
                          className="select-opt"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                          required
                        >
                          <option value="">Select Category</option>
                          <option>Gyms</option>
                          <option>Zumba</option>
                          <option>Cross Functional Training</option>
                          <option>Yoga</option>
                          <option>Fitness Studio</option>
                          <option>MMA</option>
                          <option>Kick Boxing</option>
                          <option>Dance</option>
                          <option>Pilates</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-center mt-3">
                    <button
                      type="submit"
                      className="btn btn-warning  col-white"
                    >
                      Search
                    </button>
                    &nbsp; &nbsp;
                    <button
                      type="reset"
                      className="btn btn-blue col-white"
                      onClick={reset}
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="background" data-bs-dismiss="modal" />
        </div>
        {/*Join Now Form*/}

        <div className="modal fade" id="consult" style={{ height: "100%" }}>
          <div
            className="modal-dialog modal-align-center"
            style={{ position: "relative", height: "100%", marginTop: "0%" }}
          >
            <div className="modal-contents">
              <div
                className="modal-header"
                style={{ justifyContent: "center" }}
              >
                <h4 className="modal-title">Free Consultation</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={reset}
                ></button>
              </div>

              <div className="modal-body">
                <form onSubmit={join}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12 col-sm-12">
                        <input
                          type="date"
                          min={Moment().add(0, "d").format("YYYY-MM-DD")}
                          className="form-control"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                        <p className="alert-message">{alertdate}</p>
                        <br />
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                        />
                        <p className="alert-message">{alertname}</p>

                        <br />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                        />
                        <p className="alert-message">{alertemail}</p>

                        <br />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <input
                          type="number"
                          className="form-control"
                          value={contactNumber}
                          onChange={(e) => setContactNumber(e.target.value)}
                          placeholder="Mobile Number"
                        />
                        <p className="alert-message">{alertcontactNumber}</p>

                        <br />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <select
                          name="health"
                          id="healthy"
                          className="select-opt"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option value="">Select Category</option>
                          <option value="Weight-Loss">Weight Loss</option>
                          <option value="Physiotherapy">Physiotherapy</option>
                          <option value="Travel">Travel</option>
                          <option value="Health-Care">Health Care</option>
                          <option value="Mind-Fullness">Mind Fullness</option>
                          <option value="Stress-Management">
                            Stress Management
                          </option>
                          <option value="Fitness">Fitness</option>
                        </select>
                        <p className="alert-message">{alertcategory}</p>
                        <br />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <textarea
                          row="50"
                          col="50"
                          className="form-control"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Discribe in brief"
                        ></textarea>
                        <p className="alert-message">{alertmessage}</p>

                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-warning  col-white"
                      onClick={joinfreehealth}
                    >
                      Book Now
                    </button>
                    &nbsp; &nbsp;
                    <button
                      type="reset"
                      className="btn btn-blue col-white"
                      onClick={reset}
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="background" data-bs-dismiss="modal" />
        </div>

        <div
          className="modal fade"
          id="travelenquiry"
          style={{ height: "100%" }}
        >
          <div
            className="modal-dialog modal-align-center"
            style={{ position: "relative", height: "100%", marginTop: "0%" }}
          >
            <div className="modal-contents">
              <div className="modal-header">
                <h4 className="modal-title">Travel Package Enquiry</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={clearform}
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <form onSubmit={travelpackageEnquiry}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Name"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />

                      <p className="alert-message">{alertname}</p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6" style={{ marginBottom: "5%" }}>
                      <input
                        maxlength="10"
                        type="number"
                        placeholder="Mobile Number"
                        className="form-control"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                      />

                      <p className="alert-message">{alertmobile}</p>
                    </div>

                    <div className="col-md-6" style={{ marginBottom: "5%" }}>
                      <input
                        type="email"
                        placeholder="Email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      <p className="alert-message">{alertemail}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6" style={{ marginBottom: "5%" }}>
                      <input
                        type="date"
                        name="datemin"
                        min={Moment().add(0, "d").format("YYYY-MM-DD")}
                        placeholder="Travel Date"
                        value={travelDate}
                        onChange={(e) => settravelDate(e.target.value)}
                        className="form-control"
                        id="date"
                      />
                      <p className="alert-message">{alertdate}</p>
                    </div>

                    <div className="col-md-6" style={{ marginBottom: "5%" }}>
                      <input
                        type="text"
                        placeholder="Destination"
                        className="form-control"
                        value={destination}
                        onChange={(e) => setDestination(e.target.value)}
                      />
                      <p className="alert-message"> {alertdestination}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6" style={{ marginBottom: "5%" }}>
                      <input
                        maxlength="2"
                        placeholder="No.of Adult"
                        className="form-control"
                        value={adultCount}
                        min="1"
                        onChange={(e) => setadultCount(e.target.value)}
                        id="adult"
                      />
                      <p className="alert-message">{alertadult}</p>
                    </div>

                    <div className="col-md-6" style={{ marginBottom: "5%" }}>
                      <input
                        maxlength="2"
                        placeholder="No.of Child"
                        className="form-control"
                        value={childCount}
                        onChange={(e) => setchildCount(e.target.value)}
                        id="child"
                      />
                      <p className="alert-message">{alertchild}</p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <textarea
                        placeholder="Describe in brief"
                        className="form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <p className="alert-message">{alertmessage}</p>
                    </div>
                  </div>
                </div>

                <div className="modal-footer d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-warning col-white "
                    // data-bs-dismiss="modal"
                    onClick={travelsave}
                  >
                    Submit
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white "
                    onClick={clearform}
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="background" data-bs-dismiss="modal" />
        </div>

        <div
          className="modal fade"
          id="freeconsultation"
          style={{ height: "100%" }}
        >
          <div
            className="modal-dialog modal-align-center"
            style={{ position: "relative", height: "100%", marginTop: "0%" }}
          >
            <div className="modal-contents">
              <div
                className="modal-header"
                style={{ justifyContent: "center" }}
              >
                <h4 className="modal-title">Weight Management Consultation</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={formclear}
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <div className="modal-body">
                <form onSubmit={weightform}>
                  <p className="bmi_text">
                    Gender:
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="male"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                      />
                      Male
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="Female"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                      />
                      Female
                    </span>
                    <span className="edit_radio_form">
                      <input
                        type="radio"
                        name="Female"
                        value="Female"
                        checked={gender === "Other"}
                        onChange={handleChange}
                        style={{ margin: "5px" }}
                      />
                      Other
                    </span>
                    <p className="alert-message">{alertgender2}</p>
                  </p>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <input
                          type="number"
                          className="form-control"
                          value={age}
                          onChange={(e) => setAge(e.target.value)}
                          placeholder="Enter your Age"
                        />
                        <p className="alert-message">{alertage2}</p>

                        <br />
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <select
                          name="health"
                          id="health"
                          className="select-mod"
                          value={healthConditions}
                          onChange={(e) => setHealthConditions(e.target.value)}
                        >
                          <option value="" className="">
                            Select health condition
                          </option>
                          <option value="Thyriod">Thyriod</option>
                          <option value="Acidity">Acidity</option>
                          <option value="Cholesterol">Cholesterol</option>
                          <option value="Kneepain">Knee Pain</option>
                          <option value="Backpain">Back Pain</option>
                          <option value="Diabetes">Diabetes(T2)</option>
                          <option value="BP">BP</option>
                          <option value="Prediabetes">Pre Diabetes</option>
                          <option value=" pcod">PCOD</option>
                          <option value="None">None</option>
                          <option value="Other">Other</option>
                        </select>
                        <p className="alert-message">{alerthealthcondition2}</p>
                        <br />
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <select
                          name="language"
                          id="profession"
                          value={profession}
                          onChange={(e) => setProfession(e.target.value)}
                          className="select-mod"
                        >
                          <option value="" className="">
                            Select your profession
                          </option>
                          <option value="Homemaker">Homemaker</option>
                          <option value="Self Employed">Self Employed</option>
                          <option value="Business">Business</option>
                          <option value="Doctor">Doctor</option>
                          <option value="Service">Service</option>
                          <option value="Retired">Retired</option>
                          <option value="Student">Student</option>
                          <option value="Unemployed">Unemployed</option>
                          <option value="Others">Others</option>
                        </select>
                        <p className="alert-message">{alertprofession2}</p>

                        <br />
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 pb-1">
                        <select
                          name="language"
                          id="language"
                          value={prefferedLanguage}
                          onChange={(e) => setPrefferedLanguage(e.target.value)}
                          className="select-mod"
                        >
                          <option value="" className="">
                            Select your language
                          </option>
                          <option value="Hindi">Hindi</option>
                          <option value="English">English</option>
                          <option value="Kannada">Kannada</option>
                          <option value="Marathi">Marathi</option>
                          <option value="Telugu">Telugu</option>
                          <option value="Tamil">Tamil</option>
                          <option value="Malayalam">Malayalam</option>
                          <option value="Punjabi">Punjabi</option>
                          <option value="Gujarati">Gujarati</option>
                          <option value="Bengali">Bengali</option>
                          <option value="Urdu">Urdu</option>
                        </select>
                        <p className="alert-message">
                          {alertprefferedlanguage2}
                        </p>

                        <br />
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Enter your name"
                        />
                        <p className="alert-message">{alertname2}</p>
                        <br />
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <p className="alert-message">{alertemail2}</p>
                        <br />
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <input
                          type="number"
                          className="form-control"
                          value={contactNumber}
                          onChange={(e) => setContactNumber(e.target.value)}
                          placeholder="Enter your contact number"
                        />
                        <p className="alert-message">{alertcontactnumber2}</p>

                        <br />
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <input
                          type="text"
                          className="form-control"
                          value={goal}
                          onChange={(e) => setGoal(e.target.value)}
                          placeholder="Weight Loss/ Gain Goal"
                        />
                        <p className="alert-message">{alertenquiryFor}</p>

                        <br />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <textarea
                          col="50"
                          row="50"
                          type="text"
                          className="form-control"
                          value={messages}
                          onChange={(e) => setMessages(e.target.value)}
                          placeholder="Describe in brief"
                        ></textarea>
                        <p className="alert-message">{alertmessage2}</p>

                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-warning col-white"
                      onClick={freeconsultation}
                    >
                      Book Now
                    </button>
                    &nbsp; &nbsp;
                    <button
                      type="reset"
                      className="btn btn-blue col-white"
                      onClick={formclear}
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="background" data-bs-dismiss="modal" />
        </div>

        <div
          className="modal fade"
          id="myenquirytherapy"
          style={{ height: "100%" }}
        >
          <div
            className="modal-dialog modal-align-center"
            style={{ position: "relative", height: "100%", marginTop: "0%" }}
          >
            <div className="modal-contents">
              <div className="modal-header">
                <h4 className="modal-title">Physiotherapy Enquiry</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <form onSubmit={next}>
                <div className="modal-body">
                  <input
                    type="text"
                    placeholder="Name"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <p className="alert-message">{alertname}</p>

                  <br />
                  <input
                    type="contactNumber"
                    placeholder="Mobile number"
                    className="form-control"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                  />
                  <p className="alert-message">{alertcontactNumber}</p>
                  <br />
                  <input
                    type="email"
                    placeholder="Email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p className="alert-message">{alertemail}</p>

                  <br />

                  <textarea
                    col="50"
                    row="50"
                    type="text"
                    placeholder="Describe in brief"
                    className="form-control"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <p className="alert-message">{alertmessage}</p>
                  <br />
                </div>
                <div className="modal-footer d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-warning col-white"
                    onClick={joinphy}
                  >
                    Submit
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="reset"
                    className="btn btn-blue col-white"
                    data-bs-dismiss="modal"
                    onClick={clearform}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="background" data-bs-dismiss="modal" />
        </div>

        <div className="modal fade" id="enquire" style={{ height: "100%" }}>
          <div
            className="modal-dialog modal-align-center"
            style={{ position: "relative", height: "100%", marginTop: "0%" }}
          >
            <div className="modal-contents ">
              <div
                className="modal-header"
                style={{ justifyContent: "center" }}
              >
                <h4 className="modal-title">Corporate Enquiry</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={clear}
                ></button>
              </div>

              <div className="modal-body">
                <form onSubmit={uploadcorporate}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Enter your name"
                        />
                        <p className="alert-message">{alertname}</p>

                        <br />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter the email"
                        />
                        <p className="alert-message">{alertemail}</p>

                        <br />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <input
                          type="text"
                          className="form-control"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          placeholder="Enter the company name"
                        />
                        <p className="alert-message">{alertcompanyName}</p>

                        <br />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <input
                          type="number"
                          className="form-control"
                          value={contactNumber}
                          onChange={(e) => setContactNumber(e.target.value)}
                          placeholder="Enter the contact number"
                        />
                        <p className="alert-message">{alertcontactNumber}</p>

                        <br />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <textarea
                          type="text"
                          row="50"
                          col="50"
                          className="form-control"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Describe in brief"
                        ></textarea>
                        <p className="alert-message">{alertmessage}</p>

                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-warning col-white"
                      onClick={joinen}
                    >
                      Submit
                    </button>
                    &nbsp; &nbsp;
                    <button
                      type="reset"
                      className="btn btn-blue col-white"
                      onClick={clear}
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="background" data-bs-dismiss="modal" />
        </div>
      </div>
    </div>
  );
};

export default Home;
